<template>
  <div id="app">
    <!-- <Nav /> -->
    <router-view/>
  </div>
</template>
<script>
export default {
  components: {
    Nav: () => import('@/components/NavBar.vue')
  },
  created() {
    console.log('agreement created')
  },
}
</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
html{
  background: #F4F4F4;
}
</style>
